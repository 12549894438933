h1 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary);
  font-family: 'Encode Sans', sans-serif;
}

h2 {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: var(--secondary);
  font-family: 'Encode Sans', sans-serif;
}

p {
  margin: 0;
  color: var(--secondary);
  font-family: 'Encode Sans', sans-serif;

  &.xxl {
    font-size: 32px;
  }

  &.xl {
    font-size: 24px;
  }

  &.l {
    font-size: 18px;
  }

  &.m {
    font-size: 16px;
  }

  &.s {
    font-size: 14px;
  }

  &.xs {
    font-size: 12px;
  }

  &.xxs {
    font-size: 9px;
  }

  &.grey {
    color: var(--grey);
  }

  &.white {
    color: var(--white);
  }

  &.hoverable {
    &:hover {
      color: var(--primary-hover);
    }
  }

  &.center {
    text-align: center;
  }
}

.bold {
  font-weight: 700;
}

.semi {
  font-weight: 600;
}

.regular {
  font-weight: 400;
}

.body-btn {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.underlined {
  text-decoration: underline;
  cursor: pointer;
}
