.content-element {
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 30px;
  background-color: var(--white);

  overflow: auto;
  text-overflow: clip;
  display: block;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 40px;
  background-color: var(--white);
  border-radius: 15px;
}

.validation-error {
  margin-top: 8px;
  font-size: 13px;
  line-height: 16px;
  color: var(--error);
  display: block;
}

@media screen and (max-width: 425px) {
  .container {
    margin: 16px;
    padding: 20px;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .container {
    margin: 16px;
    padding: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    margin: 16px;
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .content-element {
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }

  .wide {
    width: 100% !important;
  }

  .wide-center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
