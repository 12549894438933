:root {
  /* margin */
  --margin-tiny: 5px;
  --margin-small: 8px;
  --margin-medium: 16px;
  --margin-big: 32px;

  /* padding */
  --padding-tiny: 5px;
  --padding-small: 8px;
  --padding-medium: 16px;
  --padding-big: 32px;
}
