:root {
  --primary: #da557a;
  --primary-hover: #e24a75;
  --secondary: #353535;
  --success: #01b123;
  --error: #fd1016;
  --purple: #653b6b;
  --blue: #2699fb;
  --white: #ffffff;
  --background-white: #efefef;
  --grey: #c5c5c5;
  --light-grey: #e7e7e7;
}

h1 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--secondary);
  font-family: "Encode Sans", sans-serif;
}

h2 {
  margin: 0;
  font-size: 32px;
  font-weight: bold;
  color: var(--secondary);
  font-family: "Encode Sans", sans-serif;
}

p {
  margin: 0;
  color: var(--secondary);
  font-family: "Encode Sans", sans-serif;
}
p.xxl {
  font-size: 32px;
}
p.xl {
  font-size: 24px;
}
p.l {
  font-size: 18px;
}
p.m {
  font-size: 16px;
}
p.s {
  font-size: 14px;
}
p.xs {
  font-size: 12px;
}
p.xxs {
  font-size: 9px;
}
p.grey {
  color: var(--grey);
}
p.white {
  color: var(--white);
}
p.hoverable:hover {
  color: var(--primary-hover);
}
p.center {
  text-align: center;
}

.bold {
  font-weight: 700;
}

.semi {
  font-weight: 600;
}

.regular {
  font-weight: 400;
}

.body-btn {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.underlined {
  text-decoration: underline;
  cursor: pointer;
}

:root {
  /* margin */
  --margin-tiny: 5px;
  --margin-small: 8px;
  --margin-medium: 16px;
  --margin-big: 32px;
  /* padding */
  --padding-tiny: 5px;
  --padding-small: 8px;
  --padding-medium: 16px;
  --padding-big: 32px;
}

.content-element {
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 30px;
  background-color: var(--white);
  overflow: auto;
  text-overflow: clip;
  display: block;
}

.container {
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 40px;
  background-color: var(--white);
  border-radius: 15px;
}

.validation-error {
  margin-top: 8px;
  font-size: 13px;
  line-height: 16px;
  color: var(--error);
  display: block;
}

@media screen and (max-width: 425px) {
  .container {
    margin: 16px;
    padding: 20px;
  }
}
@media screen and (min-width: 426px) and (max-width: 768px) {
  .container {
    margin: 16px;
    padding: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    margin: 16px;
    padding: 20px;
  }
}
@media screen and (max-width: 1024px) {
  .content-element {
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .wide {
    width: 100% !important;
  }
  .wide-center {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.m-big {
  margin: var(--margin-big);
}

.m-medium {
  margin: var(--margin-medium);
}

.m-small {
  margin: var(--margin-small);
}

.m-tiny {
  margin: var(--margin-tiny);
}

.mb-big {
  margin-bottom: var(--margin-big);
}

.mb-medium {
  margin-bottom: var(--margin-medium);
}

.mb-small {
  margin-bottom: var(--margin-small);
}

.mb-tiny {
  margin-bottom: var(--margin-tiny);
}

.ml-big {
  margin-left: var(--margin-big);
}

.ml-medium {
  margin-left: var(--margin-medium);
}

.ml-small {
  margin-left: var(--margin-small);
}

.ml-tiny {
  margin-left: var(--margin-tiny);
}

.mt-big {
  margin-top: var(--margin-big);
}

.mt-medium {
  margin-top: var(--margin-medium);
}

.mt-small {
  margin-top: var(--margin-small);
}

.mt-tiny {
  margin-top: var(--margin-tiny);
}

.mr-big {
  margin-right: var(--margin-big);
}

.mr-medium {
  margin-right: var(--margin-medium);
}

.mr-small {
  margin-right: var(--margin-small);
}

.mr-tiny {
  margin-right: var(--margin-tiny);
}

.p-big {
  padding: var(--padding-big);
}

.p-medium {
  padding: var(--padding-medium);
}

.p-small {
  padding: var(--padding-small);
}

.p-tiny {
  padding: var(--padding-tiny);
}

.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row.center {
  align-items: center;
}
.flex-row.center-v {
  justify-content: center;
}
.flex-row.space-around {
  justify-content: space-around;
}
.flex-row.space-between {
  justify-content: space-between;
}
.flex-row.left {
  justify-content: left;
}
.flex-row.right {
  justify-content: right;
}
.flex-row.wrap {
  flex-wrap: wrap;
}
.flex-row.wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-column.space-around {
  justify-content: space-around;
}
.flex-column.space-between {
  justify-content: space-between;
}
.flex-column.center {
  justify-content: center;
}
.flex-column.center-v {
  align-items: center;
}

.ng-tooltip {
  position: absolute;
  letter-spacing: 0;
  color: var(--white);
  padding: 8px 16px;
  background: var(--secondary);
  border-radius: 5px;
  z-index: 1000;
  opacity: 0;
  font-size: 12px;
}

.ng-tooltip:after {
  content: "";
  position: absolute;
  border-style: solid;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
}

.ng-tooltip-show {
  opacity: 1;
}

#language-label {
  display: none;
}

.ng-select.custom .ng-select-container {
  color: #333;
  background-color: #fff;
  border: none;
  min-height: 36px;
  align-items: center;
}
.ng-select.custom .ng-select-container:hover {
  box-shadow: none;
}

.ng-dropdown-panel {
  border: none;
  box-shadow: 0 10px 20px rgba(200, 200, 200, 0.3019607843);
  border-radius: 15px;
  left: 0;
}
.ng-dropdown-panel.ng-select-bottom {
  top: 130%;
  border-top-color: #e6e6e6;
  margin-top: -1px;
  border-radius: 15px;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding: 12px;
  top: 20px;
}
.ng-dropdown-panel.ng-dropdown-panel .scroll-host {
  border-radius: 15px;
}

.ng-option {
  color: #333;
  background-color: #ebf5ff;
  border-bottom: none;
}
.ng-option:not(:last-child) {
  padding: 15px;
  border-bottom: 1px solid #bfbfbf;
}

@media screen and (max-width: 425px) {
  #language-label {
    display: block;
  }
  .ng-select.mobile {
    height: 43px;
    padding: 2px 0;
    width: 100%;
    border: 1px solid var(--grey);
    border-radius: 15px;
  }
  .ng-select.mobile .ng-select-container {
    background: none;
  }
  .ng-select.mobile .ng-dropdown-panel {
    border: 1px solid var(--grey);
  }
}
ngneat-dialog input[type=password]:not(.browser-default),
ngneat-dialog input[type=text]:not(.browser-default) {
  margin: 0;
}
ngneat-dialog .ngneat-dialog-backdrop {
  padding: 0;
  z-index: 50;
}
ngneat-dialog .ngneat-dialog-backdrop.ngneat-dialog-backdrop-visible {
  background: var(--dialog-backdrop-bg, rgba(0, 0, 0, 0.75));
}
ngneat-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
  padding: 30px;
  animation: none;
  border-radius: 30px;
}
ngneat-dialog .ngneat-dialog-backdrop .ngneat-dialog-content .ngneat-close-dialog {
  position: absolute;
  top: 25px;
  right: 25px;
  background-color: transparent;
}
ngneat-dialog .ngneat-dialog-backdrop .ngneat-dialog-content .ngneat-close-dialog svg {
  color: var(--secondary);
}
ngneat-dialog .ngneat-dialog-backdrop .ngneat-dialog-content .ngneat-close-dialog:hover {
  background-color: var(--primary);
}
ngneat-dialog .ngneat-dialog-backdrop .ngneat-dialog-content .ngneat-close-dialog:hover svg {
  color: var(--white);
}

#design-history-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
  margin: 0 16px;
  padding: 20px;
  animation: none;
}
@media screen and (min-width: 426px) and (max-width: 1024px) {
  #design-history-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
    margin: 0 16px;
    padding: 30px;
  }
}
@media screen and (min-width: 1025px) {
  #design-history-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
    width: 80% !important;
    padding: 30px;
  }
}

#filter-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
  margin: 0 16px;
  padding: 20px;
  animation: none;
  border-radius: 15px;
}

#image-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
  padding: 60px;
  animation: none;
  box-shadow: none;
  background: transparent;
}
#image-dialog svg {
  transform: scale(1.25);
  color: var(--white);
}

#submenus-dialog .ngneat-dialog-backdrop .ngneat-dialog-content {
  align-self: flex-end;
  top: -12vh;
  padding: 15px;
  animation: none;
  box-shadow: none;
  background: transparent;
}

.text-error {
  text-align: left;
  font-weight: bold;
  color: var(--error);
}

.toast-container .ngx-toastr {
  padding: 26px 16px 26px 46px !important;
  width: 530px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  background-size: 20px !important;
}

.toast-top-right {
  top: 85px !important;
  right: 30px !important;
}

.toast-success {
  background-color: #EFFAF0 !important;
  border: 1px solid #01B123 !important;
  color: #01B123 !important;
  background-image: url("/assets/images/icons/toast/check-circle.svg") !important;
}

.toast-error {
  background-color: #FFF2F3 !important;
  border: 1px solid #FD1016 !important;
  color: #FD1016 !important;
  background-image: url("/assets/images/icons/toast/cross-circle.svg") !important;
}

html {
  font-family: "Encode Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: var(--background-white);
}

.temporary-hidden {
  display: none;
}

/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}