#language-label {
  display: none;
}

.ng-select.custom .ng-select-container {
  color: #333;
  background-color: #fff;
  border: none;
  min-height: 36px;
  align-items: center;

  &:hover {
    box-shadow: none;
  }
}

.ng-dropdown-panel {
  border: none;
  box-shadow: 0 10px 20px #c8c8c84d;
  border-radius: 15px;
  left: 0;

  &.ng-select-bottom {
    top: 130%;
    border-top-color: #e6e6e6;
    margin-top: -1px;
    border-radius: 15px;
  }

  .ng-dropdown-panel-items .ng-option {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    padding: 12px;
    top: 20px;
  }

  &.ng-dropdown-panel .scroll-host {
    border-radius: 15px;
  }
}

.ng-option {
  color: #333;
  background-color: #ebf5ff;
  border-bottom: none;

  &:not(:last-child) {
    padding: 15px;
    border-bottom: 1px solid #bfbfbf;
  }
}

@media screen and (max-width: 425px) {
  #language-label {
    display: block;
  }

  .ng-select.mobile {
    height: 43px;
    padding: 2px 0;
    width: 100%;
    border: 1px solid var(--grey);
    border-radius: 15px;

    .ng-select-container {
      background: none;
    }

    .ng-dropdown-panel {
      border: 1px solid var(--grey);
    }
  }
}
