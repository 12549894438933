.m-big {
  margin: var(--margin-big);
}

.m-medium {
  margin: var(--margin-medium);
}

.m-small {
  margin: var(--margin-small);
}

.m-tiny {
  margin: var(--margin-tiny);
}

.mb-big {
  margin-bottom: var(--margin-big);
}

.mb-medium {
  margin-bottom: var(--margin-medium);
}

.mb-small {
  margin-bottom: var(--margin-small);
}

.mb-tiny {
  margin-bottom: var(--margin-tiny);
}

.ml-big {
  margin-left: var(--margin-big);
}

.ml-medium {
  margin-left: var(--margin-medium);
}

.ml-small {
  margin-left: var(--margin-small);
}

.ml-tiny {
  margin-left: var(--margin-tiny);
}

.mt-big {
  margin-top: var(--margin-big);
}

.mt-medium {
  margin-top: var(--margin-medium);
}

.mt-small {
  margin-top: var(--margin-small);
}

.mt-tiny {
  margin-top: var(--margin-tiny);
}

.mr-big {
  margin-right: var(--margin-big);
}

.mr-medium {
  margin-right: var(--margin-medium);
}

.mr-small {
  margin-right: var(--margin-small);
}

.mr-tiny {
  margin-right: var(--margin-tiny);
}

.p-big {
  padding: var(--padding-big);
}

.p-medium {
  padding: var(--padding-medium);
}

.p-small {
  padding: var(--padding-small);
}

.p-tiny {
  padding: var(--padding-tiny);
}

.flex-row {
  display: flex;
  flex-direction: row;

  &.center {
    align-items: center;
  }

  &.center-v {
    justify-content: center;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.left {
    justify-content: left;
  }

  &.right {
    justify-content: right;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.wrap-reverse {
    flex-wrap: wrap-reverse;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;

  &.space-around {
    justify-content: space-around;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.center-v {
    align-items: center;
  }
}
