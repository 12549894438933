.ng-tooltip {
  position: absolute;
  letter-spacing: 0;
  color: var(--white);
  padding: 8px 16px;
  background: var(--secondary);
  border-radius: 5px;
  z-index: 1000;
  opacity: 0;
  font-size: 12px;
}

.ng-tooltip:after {
  content: '';
  position: absolute;
  border-style: solid;
}

.ng-tooltip-bottom:after {
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
}

.ng-tooltip-show {
  opacity: 1;
}
