:root {
  --primary: #da557a;
  --primary-hover: #e24a75;
  --secondary: #353535;
  --success: #01b123;
  --error: #fd1016;

  --purple: #653b6b;
  --blue: #2699fb;

  --white: #ffffff;
  --background-white: #efefef;

  --grey: #c5c5c5;
  --light-grey: #e7e7e7;
}
