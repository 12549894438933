ngneat-dialog {
  input[type='password']:not(.browser-default),
  input[type='text']:not(.browser-default) {
    margin: 0;
  }

  .ngneat-dialog-backdrop {
    padding: 0;
    z-index: 50;

    &.ngneat-dialog-backdrop-visible {
      background: var(--dialog-backdrop-bg, rgba(0, 0, 0, 0.75));
    }

    .ngneat-dialog-content {
      padding: 30px;
      animation: none;
      border-radius: 30px;

      .ngneat-close-dialog {
        position: absolute;
        top: 25px;
        right: 25px;
        background-color: transparent;

        svg {
          color: var(--secondary);
        }

        &:hover {
          background-color: var(--primary);

          svg {
            color: var(--white);
          }
        }
      }
    }
  }
}

#design-history-dialog {
  .ngneat-dialog-backdrop {
    .ngneat-dialog-content {
      margin: 0 16px;
      padding: 20px;
      animation: none;
    }
  }

  @media screen and (min-width: 426px) and (max-width: 1024px) {
    .ngneat-dialog-backdrop {
      .ngneat-dialog-content {
        margin: 0 16px;
        padding: 30px;
      }
    }
  }

  @media screen and (min-width: 1025px) {
    .ngneat-dialog-backdrop {
      .ngneat-dialog-content {
        width: 80% !important;
        padding: 30px;
      }
    }
  }
}

#filter-dialog {
  .ngneat-dialog-backdrop {
    .ngneat-dialog-content {
      margin: 0 16px;
      padding: 20px;
      animation: none;
      border-radius: 15px;
    }
  }
}

#image-dialog {
  .ngneat-dialog-backdrop {
    .ngneat-dialog-content {
      padding: 60px;
      animation: none;
      box-shadow: none;
      background: transparent;
    }
  }

  svg {
    transform: scale(1.25);
    color: var(--white);
  }
}

#submenus-dialog {
  .ngneat-dialog-backdrop {
    .ngneat-dialog-content {
      align-self: flex-end;
      top: -12vh;
      padding: 15px;
      animation: none;
      box-shadow: none;
      background: transparent;
    }
  }
}

.text-error {
  text-align: left;
  font-weight: bold;
  color: var(--error);
}
