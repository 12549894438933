.toast-container .ngx-toastr {
  padding: 26px 16px 26px 46px !important;
  width: 530px !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  background-size: 20px !important;
}

.toast-top-right {
  top: 85px !important;
  right: 30px !important;
}

.toast-success {
  background-color: #EFFAF0 !important;
  border: 1px solid #01B123 !important;
  color: #01B123 !important;
  background-image: url("/assets/images/icons/toast/check-circle.svg") !important;
}

.toast-error {
  background-color: #FFF2F3 !important;
  border: 1px solid #FD1016 !important;
  color: #FD1016 !important;
  background-image: url("/assets/images/icons/toast/cross-circle.svg") !important;
}
