@import 'colors';
@import 'typography';
@import 'variables';
@import 'content';
@import 'positioning';
@import 'custom-controls/tooltip';
@import 'custom-controls/ng-select';
@import 'custom-controls/dialog';
@import 'custom-controls/ngx-toastr';

html {
  font-family: 'Encode Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  overflow: auto;
  background-color: var(--background-white);
}

.temporary-hidden {
  display: none;
}
